import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  GetProductPaginate,
  ActiveInactiveProduct,
  SetFeatureProduct,
  DeleteProductById,
} from "../../../Services/Api/product";
import { AdminFooter } from "../../Layout";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tooltip from "@mui/material/Tooltip";
import {
  ErrorToast,
  Confirmation,
  SuccessToast,
} from "../../../Utils/SweetAlert";

const Product = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [productListPagination, setProductListPagination] = useState([]);
  const [searchInputWord, setSearchInputWord] = useState(null);
  const [searchWord, setSearchWord] = useState(null);
  const navigate = useNavigate();

  const GetProductList = async (limit = "", page = "", searchText = null) => {
    setIsLoading(true);
    await GetProductPaginate({
      limit: limit,
      page: page,
      searchText: searchText || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setProductListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setProductListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setProductListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await GetProductList(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await GetProductList(parseInt(event.target.value, 10), 0);
  };

  const deleteProductById = (productId) => {
    Confirmation("Want to delete product?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteProductById(productId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Product Deleted.");
            GetProductList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const changeActive = (product, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await ActiveInactiveProduct(product?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetProductList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const changeFeature = (product, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await SetFeatureProduct(product?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetProductList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleAlphbetSearch = () => {
    if (searchInputWord?.length >= 1) {
      setSearchWord(searchInputWord);
    }
  };

  useEffect(() => {
    GetProductList(limit, page, searchWord);
  }, [limit, page, searchWord]);

  return (
    <>
      <Helmet>
        <title>Products | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Products</h3>
              </div>
              <div className="col-auto">
                <Link to="/admin/add-product" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add Product
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="search-filter mt-2">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchInputWord(e?.target?.value)}
                      value={searchInputWord || ""}
                    />
                    <button
                      className="btn btnstl btn-primary"
                      onClick={(e) => handleAlphbetSearch()}
                    >
                      <SearchIcon />
                    </button>
                    <button
                      className="btn btnstl btn-dark"
                      onClick={(e) => window.location.reload()}
                    >
                      <RestartAltIcon />
                    </button>
                  </div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Language</th>
                          <th>Category</th>
                          <th>Sections</th>
                          <th>Status</th>
                          <th>Feature</th>
                          <th>Created/Updated</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {productListPagination.map((product, index) => (
                              <tr key={index}>
                                <td>
                                  <h2 className="table-avatar">
                                    <Link to="#">{product?.name || ""}</Link>
                                  </h2>
                                </td>
                                <td>{product?.language?.name || ""}</td>
                                <td>{product?.category?.name || ""}</td>
                                <td>{product?.sections?.length || 0}</td>
                                <td>
                                  {product?.status === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeActive(
                                          product,
                                          "Want to active product?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {product?.status === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeActive(
                                          product,
                                          "Want to deactivate product?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {product?.is_feature === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeFeature(
                                          product,
                                          "Want to feature this product?"
                                        );
                                      }}
                                    >
                                      Feature
                                    </button>
                                  )}
                                  {product?.is_feature === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeFeature(
                                          product,
                                          "Want to un-feature this product?"
                                        );
                                      }}
                                    >
                                      Featured
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {moment(product?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || "-"}{" "}
                                  <br />{" "}
                                  {moment(product?.updatedOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || "-"}
                                </td>
                                <td className="text-center">
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate("/admin/edit-product", {
                                        state: { id: product?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteProductById(product?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                            {productListPagination.length === 0 && (
                              <tr>
                                <td colSpan={8}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={8}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
