import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { setPageWrapperTaggleState } from "../../../Services/Api/actions";
import UserHeader from "./UserHeader";
import UserSidebar from "./UserSidebar";

const UserLayout = ({ userDetails }) => {
  const userData = userDetails || {};
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(true);
  const [sidebarDesktopToggel, setSidebarDesktopToggel] = useState(false);
  const sidebarRef = useRef(null); // used to get sidebar width
  const [isMobile, setMobile] = useState(document.body.clientWidth < 991);
  // use 'init' | 'open' | 'close', that you don't need remember if suer clicked
  const [sidebarStatus, setSidebarStatus] = useState("init");

  useEffect(() => {
    // add listener only once, or many listeners would be created every render
    const mq = window.matchMedia("(max-width: 991px)");
    mq.addListener((res) => {
      setMobile(res.matches);
    });
    return () => mq.removeListener(toggleSidebar);
  }, []);

  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");

  const toggleSidebar = (open) => {
    setSidebarStatus(open ? "open" : "close");
  };

  const handleDesktopToggel = (sidebarDesktopToggel) => {
    setSidebarDesktopToggel(!isMobile && sidebarDesktopToggel ? false : true);
    dispatch(setPageWrapperTaggleState(!isMobile && sidebarDesktopToggel ? false : true));
  };

  const handleShowSidebar = (localShowSidear) => {
    setSidebarDesktopToggel(false);
    toggleSidebar(!localShowSidear);
    dispatch(setPageWrapperTaggleState(!isMobile && sidebarDesktopToggel ? false : true));
  };

  return (
    <>
      <div
        className={`${sidebarDesktopToggel
            ? "header header-one header-desktop-toggel"
            : "header header-one"
          }`}
      >
        <UserHeader
          onClick={toggleSidebar}
          showSideBar={showSidebar}
          userData={userData}
          sidebarDesktopToggel={sidebarDesktopToggel}
          handleDesktopToggel={handleDesktopToggel}
          handleShowSidebar={handleShowSidebar}
        />
      </div>
      <div
        className={`sidebar ${showSidebar ? "opened" : ""} ${sidebarDesktopToggel ? "sidebar-desktop-toggel" : ""
          }`}
        id="sidebar"
        ref={sidebarRef}
      >
        {showSidebar && (
          <UserSidebar
            setOpenSidebar={setOpenSidebar}
            openSidebar={openSidebar}
            handleonClick={toggleSidebar}
            showSideBar={showSidebar}
            setSidebarStatus={setSidebarStatus}
            userData={userData}
            isMobile={isMobile}
            sidebarDesktopToggel={sidebarDesktopToggel}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(UserLayout);
