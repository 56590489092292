import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const GetAllDashBoardService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/dashboard`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetContactusService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/contactus/paginate`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCreditHistoryService = async ({ limit, page, searchText }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let search_text = searchText !== null ? `&search=${searchText}` : ``;
      resolve(
        await Api.get(
          `/client/credit-history?per_page=${limit}&page=${page}${search_text}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetUserComman = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/user/profile`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetAllDashBoard = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/dashboard`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetDashboardProductList = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/dashboard/product-list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetAllSetting = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/setting/paymentgetway`));
    } catch (error) {
      reject(error);
    }
  });
};

export const SendContactus = async (postData) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post(`/contactus`, postData));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** Front Open API **********************/

export const GetFrontFaqPaginate = async ({ limit, page, order_by, order }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(
          `/faq/home/paginate?per_page=${limit}&page=${page}&order_by=${order_by}&order=${order}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};
