import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Banner = ({ fullBanner }) => {
  useEffect(() => {}, []);

  return fullBanner ? (
    <div className="main-banner" id="top">
      <section className="hero-sec">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} md={6}>
              <div className="content">
                <h2 className="title">
                  Practice + Feedback = <span>Interpreter Mastery</span>
                </h2>
                <h5 className="sub-title">
                  Aspiring to excel in interpreting? Enhance your skills with
                  real-world dialogues and a vast vocabulary on PhrasePulse! Our
                  platform offers comprehensive tools and resources to help you
                  excel in your interpreting career.
                </h5>
                <Link className="btn btn-primary" to="/user/auth/login">
                  Practice More
                </Link>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="hero-img">
                <img src="/inner-assets/img/front/hero-slider.png" alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  ) : (
    <div className="main-banner" id="top">
      <Container></Container>
    </div>
  );
};

export default Banner;
