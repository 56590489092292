import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Pagination } from "../../../Utils";
import { AdminFooter } from "../../Layout";
import { GetExercisePaginateService } from "../../../Services/Api/exercise";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const Exercise = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [exerciseListPagination, setexerciseListPagination] = useState([]);
  const [searchInputWord, setSearchInputWord] = useState(null);
  const [searchWord, setSearchWord] = useState(null);
  const navigate = useNavigate();

  const GetExerciseList = async (limit = "", page = "", searchText = null) => {
    setIsLoading(true);
    await GetExercisePaginateService({
      limit: limit,
      page: page,
      searchText: searchText || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setexerciseListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setexerciseListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setexerciseListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAlphbetSearch = () => {
    if (searchInputWord?.length >= 1) {
      setSearchWord(searchInputWord);
    }
  };

  useEffect(() => {
    GetExerciseList(limit, page, searchWord);
  }, [limit, page, searchWord]);

  return (
    <>
      <Helmet>
        <title>Exercise | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Exercises</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="search-filter mt-2">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchInputWord(e?.target?.value)}
                      value={searchInputWord || ""}
                    />
                    <button
                      className="btn btnstl btn-primary"
                      onClick={(e) => handleAlphbetSearch()}
                    >
                      <SearchIcon />
                    </button>
                    <button
                      className="btn btnstl btn-dark"
                      onClick={(e) => window.location.reload()}
                    >
                      <RestartAltIcon />
                    </button>
                  </div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Start Date</th>
                          <th>Exercise Name</th>
                          <th>Language</th>
                          <th>Category</th>
                          <th>User</th>
                          <th>Credit Used</th>
                          <th>Status</th>
                          <th>Detail Result</th>
                          <th>Marks</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {exerciseListPagination.map((exercise, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(exercise?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || ""}
                                </td>
                                <td>{exercise?.product?.name || ""}</td>
                                <td>{exercise?.language?.name || ""}</td>
                                <td>{exercise?.category?.name || ""}</td>
                                <td>
                                  {exercise?.user?.name || ""} -{" "}
                                  {exercise?.user?.email || ""}
                                </td>
                                <td>{exercise?.credit_used || 0}</td>
                                <td>
                                  {exercise?.status === 2
                                    ? "Completed"
                                    : "Running"}
                                </td>
                                <td>
                                  {exercise?.isDetailedResult
                                    ? "Requested"
                                    : "-"}
                                </td>
                                <td>{exercise?.marks || "-"}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() =>
                                      navigate("/admin/edit-exercise", {
                                        state: { id: exercise?._id },
                                      })
                                    }
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                            {exerciseListPagination.length === 0 && (
                              <tr>
                                <td colSpan={10}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={10}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={10}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Exercise;
