import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const AddProductService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/product/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetProductPaginate = async ({ limit, page, searchText }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let search_text = searchText !== null ? `&search=${searchText}` : ``;

      resolve(
        await Api.get(
          `/product/paginate?per_page=${limit}&page=${page}${search_text}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteProductById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/product/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveProduct = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/product/change-status/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const SetFeatureProduct = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/product/change-feature/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetProductByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/product/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditProductService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/product/update/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCategoryListService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/category/list`));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetProductsByCategoryId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/product/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetProductRendomByCategoryId = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/product/random/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetProductById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/product/by-id/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
