import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { GetSupportPaginateService } from "../../../Services/Api/support";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ReplyIcon from "@mui/icons-material/Reply";
import { AdminFooter } from "../../Layout";
import moment from "moment";

const Support = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [supportListPagination, setSupportListPagination] = useState([]);
  const navigate = useNavigate();

  const getSupportPaginate = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetSupportPaginateService({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setSupportListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setSupportListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setSupportListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getSupportPaginate(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getSupportPaginate(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    getSupportPaginate(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Supports | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Support</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Action</th>
                          <th>Created</th>
                          <th>SupportID</th>
                          <th>Client</th>
                          <th>Image</th>
                          <th>Subject</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {supportListPagination.map((support, index) => (
                              <tr key={index}>
                                <td className="text-center">
                                  <Tooltip
                                    title="Reply"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate("/admin/reply-support", {
                                        state: { id: support?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <ReplyIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>{moment(support?.createdOn).format(
                                  "DD-MM-YYYY hh:mm A"
                                ) || ""}</td>
                                <td>{support?._id || ""}</td>
                                <td>
                                  {support?.user?.name || ""} -{" "}
                                  {support?.user?.email || ""}
                                </td>
                                <td>
                                  {support?.attachments?.[0]?.file_url && (
                                    <Link to={support?.attachments?.[0]?.file_url} target="_blank">
                                      <img
                                        src={support?.attachments?.[0]?.file_url}
                                        alt="img"
                                        className="img-thumbnail me-2"
                                        style={{ maxWidth: "200px" }}
                                      />
                                    </Link>
                                  )}
                                </td>
                                <td>{support?.subject || ""}</td>
                                <td>{support?.message || ""}</td>
                              </tr>
                            ))}
                            {supportListPagination.length === 0 && (
                              <tr>
                                <td colSpan={7}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={7}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
