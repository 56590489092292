import { Route, Routes } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { UserLayout } from "../Components/User/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect, useState } from "react";
import Dashboard from "../Components/User/Dashboard";
import Faq from "../Components/User/Faq";
import WordStock from "../Components/User/WordStock";
import Pricing from "../Components/User/Pricing";
import Feedback from "../Components/User/Feedback";
import Support from "../Components/User/Support";
import Profile from "../Components/User/Profile";
import Updates from "../Components/User/Updates";
import Marking from "../Components/User/Marking";
import PaypalCredit from "../Components/User/PaypalCredit/PaypalCredit";
import Exercise from "../Components/User/Exercise/Exercise";
import ExerciseList from "../Components/User/Exercise/ExerciseList";
import Completion from "../Components/User/Credit/Completion";
import Notifications from "../Components/User/Notification";
import { UserLogoutService } from "../Services/Api/auth";
import { GetAllLanguage } from "../Services/Api/language";
import LanguageModal from "../Components/User/Layout/LanguageModal";
import {
  _getUserDetails,
  setBrowserData,
  setDeviceData,
} from "../Services/Api/actions";
import { detectBrowser, deviceDetection } from "../Utils/CommanFunctions";
import { Loader } from "../Utils/Loader";
import { SuccessToast } from "../Utils/SweetAlert";

const userLogoutService = async (data) => {
  await UserLogoutService(data)
    .then((res) => {
      localStorage?.removeItem("userAccessToken");
      SuccessToast("Logout successfully!!");
      window.location.assign("/");
    })
    .catch((err) => {
      localStorage?.removeItem("userAccessToken");
      window.location.assign("/");
    });
};

const Logout = () => {
  userLogoutService();
};
const UserRoutes = ({ loading, pageWrapperToggle, userDetails, getUserDetails }) => {
  const [userData, setUserData] = useState(userDetails || {});
  const [languages, setLanguages] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserLoading(true);
    getUserDetails()
      .then((res) => {
        setUserData(res);
        if (!res?.user?.language_id) {
          GetAllLanguage().then((res) => {
            if (res?.data?.data?.length > 0) {
              let lanarr = res?.data?.data;
              lanarr = lanarr.map((value) => {
                return {
                  label: value.name,
                  value: value._id,
                };
              });
              setLanguages(lanarr);
            }
          });
        }
        setUserLoading(false);
      })
      .catch((err) => {
        setUserData({});
        setUserLoading(false);
      });
  }, [getUserDetails]);

  useEffect(() => {
    if (localStorage?.getItem("userAccessToken")) {
      setUserLoading(false);
    } else {
      setUserLoading(true);
      window.location.assign("/user/auth/login");
    }
  }, []);

  useEffect(() => {
    dispatch(setBrowserData(detectBrowser()));
    dispatch(setDeviceData(deviceDetection()));
  }, [dispatch]);

  return !userLoading ? (
    <>
      {!userData?.user?.language_id && !loading && (
        <LanguageModal languages={languages} userData={userData} />
      )}

      {userData?.user?.language_id && (
        <>
          <UserLayout />
          <div className={pageWrapperToggle ? `page-main-wrapper page-wrapper-toggel` : `page-main-wrapper`}>
            <Routes>
              <Route
                exact
                path="/"
                element={<PrivateRoute Component={Dashboard} />}
              />
              <Route
                exact
                path="/dashboard"
                element={<PrivateRoute Component={Dashboard} />}
              />
              <Route
                exact
                path="/profile"
                element={<PrivateRoute Component={Profile} />}
              />
              <Route
                exact
                path="/updates"
                element={<PrivateRoute Component={Updates} />}
              />
              <Route
                exact
                path="/marking"
                element={<PrivateRoute Component={Marking} />}
              />
              <Route
                exact
                path="/credit"
                element={<PrivateRoute Component={PaypalCredit} />}
              />
              <Route
                exact
                path="/completion"
                element={<PrivateRoute Component={Completion} />}
              />
              <Route
                exact
                path="/exercises"
                element={<PrivateRoute Component={ExerciseList} />}
              />
              <Route
                exact
                path="/start-exercise"
                element={<PrivateRoute Component={Exercise} />}
              />
              <Route
                exact
                path="/stock"
                element={<PrivateRoute Component={WordStock} />}
              />
              <Route
                exact
                path="/plans"
                element={<PrivateRoute Component={Pricing} />}
              />
              <Route
                exact
                path="/faq"
                element={<PrivateRoute Component={Faq} />}
              />
              <Route
                exact
                path="/feedback"
                element={<PrivateRoute Component={Feedback} />}
              />
              <Route
                exact
                path="/support"
                element={<PrivateRoute Component={Support} />}
              />
              <Route
                exact
                path="/notifications"
                element={<PrivateRoute Component={Notifications} />}
              />
              <Route exact path="/logout" element={<Logout />} />
            </Routes>
          </div>
        </>
      )}
    </>
  ) : (
    <>
      <Loader loading={userLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    pageWrapperToggle: state?.reducers?.pageWrapperToggle,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {
    getUserDetails: () => actions(_getUserDetails()),
  };
};

export default connect(mapStateToProps, mapActionsToProps)(UserRoutes);
