import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { UserFooter } from "../Layout";

const Marking = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Marking | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper marking-page">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Marking</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Marking</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="faq-hero">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h3>PhrasePulse Scoring Guide</h3>
                  <div className="text">
                    <p>
                      Clear, fair, and actionable feedback for language improvement
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="faq-img">
                    <img
                      className="img-responsive w-auto"
                      src="/inner-assets/img/exam-score.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <Col lg={12} md={12} xs={12}>
              <div className="card">
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <div className="card-body">
                      <h4 className="mt-3 mb-3">Introduction</h4>
                      <p>
                        At PhrasePulse, we evaluate dialogues using a two-part approach:
                      </p>
                      <ul>
                        <li>
                          <strong>Category-Based Scoring:</strong> Allocate up to 100
                          points across five key skill areas.
                        </li>
                        <li>
                          <strong>Error Deductions:</strong> Adjust points based on
                          dialogue complexity (Simple, Standard, or Complex).
                        </li>
                      </ul>
                      <p>
                        This guide explains our scoring system, provides reference
                        tables, and walks you through an example calculation.
                      </p>

                      <h4 className="mt-5 mb-3">
                        1. Category-Based Scoring (Total: 100 Points)
                      </h4>
                      <p>
                        The system is divided into five categories so students can
                        easily see their strengths and areas for improvement:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Max Points</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Content &amp; Relevance</td>
                              <td>20</td>
                              <td>Addresses the topic fully with relevant details.</td>
                            </tr>
                            <tr>
                              <td>Grammar &amp; Syntax</td>
                              <td>25</td>
                              <td>
                                Proper sentence structure, tenses, and overall grammatical
                                accuracy.
                              </td>
                            </tr>
                            <tr>
                              <td>Vocabulary &amp; Expression</td>
                              <td>20</td>
                              <td>
                                Diverse, context-appropriate words with minimal
                                repetition.
                              </td>
                            </tr>
                            <tr>
                              <td>Fluency &amp; Delivery</td>
                              <td>20</td>
                              <td>
                                Smooth flow of speech or writing with minimal hesitations.
                              </td>
                            </tr>
                            <tr>
                              <td>Organization &amp; Clarity</td>
                              <td>15</td>
                              <td>
                                Logical arrangement of ideas with clear transitions.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total</strong>
                              </td>
                              <td colSpan="2">
                                <strong>100 Points</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        Each category starts at its maximum, and points are deducted
                        based on errors identified in that area.
                      </p>

                      <h4 className="mt-5 mb-3">2. Detailed Error Deduction Table</h4>
                      <p>Deductions vary with dialogue complexity. For example:</p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Error Type</th>
                              <th>Simple</th>
                              <th>Standard</th>
                              <th>Complex</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Grammar Minor</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Grammar Major</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                            <tr>
                              <td>Omission</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Distortion</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                            <tr>
                              <td>Wrong Sentence Formation</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                            <tr>
                              <td>Unidiomatic Usage</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Hesitation (Mild)</td>
                              <td>0.2</td>
                              <td>0.175</td>
                              <td>0.15</td>
                            </tr>
                            <tr>
                              <td>Hesitation (Severe)</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Long Pause (Mild)</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Long Pause (Severe)</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                            <tr>
                              <td>Pronunciation Minor</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Pronunciation Major</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                            <tr>
                              <td>Tone &amp; Intonation (Minor)</td>
                              <td>0.4</td>
                              <td>0.35</td>
                              <td>0.3</td>
                            </tr>
                            <tr>
                              <td>Tone &amp; Intonation (Major)</td>
                              <td>0.8</td>
                              <td>0.7</td>
                              <td>0.6</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        You can adjust or merge error types (for example, combining mild
                        and severe hesitations) according to your grading policy.
                      </p>

                      <h4 className="mt-5 mb-3">
                        3. Data Collection &amp; Error Counting
                      </h4>
                      <p>
                        Record each dialogue’s errors using a simple table or web form.
                        For example, you might track:
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Column</th>
                              <th>Purpose</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Student</td>
                              <td>Name (e.g., "Student A")</td>
                            </tr>
                            <tr>
                              <td>DialogueID</td>
                              <td>Identifier (e.g., "D1", "D2")</td>
                            </tr>
                            <tr>
                              <td>Complexity</td>
                              <td>"Simple", "Standard", or "Complex"</td>
                            </tr>
                            <tr>
                              <td>Error Counts</td>
                              <td>
                                Entries for each error type (e.g., Grammar Minor, etc.)
                              </td>
                            </tr>
                            <tr>
                              <td>Final Score</td>
                              <td>
                                A formula or calculation that uses the data in each error
                                column to compute the final score.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>
                        <strong>Example Entry:</strong>
                      </p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Student</th>
                              <th>DialogueID</th>
                              <th>Complexity</th>
                              <th>Grammar Minor</th>
                              <th>Grammar Major</th>
                              <th>Omission</th>
                              <th>Distortion</th>
                              <th>Final Score</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Student A</td>
                              <td>D1</td>
                              <td>Simple</td>
                              <td>2</td>
                              <td>1</td>
                              <td>1</td>
                              <td>0</td>
                              <td>(Formula)</td>
                            </tr>
                            <tr>
                              <td>Student A</td>
                              <td>D2</td>
                              <td>Standard</td>
                              <td>0</td>
                              <td>1</td>
                              <td>0</td>
                              <td>1</td>
                              <td>(Formula)</td>
                            </tr>
                            <tr>
                              <td>Student A</td>
                              <td>D3</td>
                              <td>Complex</td>
                              <td>2</td>
                              <td>0</td>
                              <td>3</td>
                              <td>0</td>
                              <td>(Formula)</td>
                            </tr>
                            <tr>
                              <td>Student B</td>
                              <td>D1</td>
                              <td>Simple</td>
                              <td>1</td>
                              <td>2</td>
                              <td>0</td>
                              <td>1</td>
                              <td>(Formula)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p>Each row represents a dialogue from one student.</p>

                      <h4 className="mt-5 mb-3">4. Mapping Errors to Categories</h4>
                      <p>
                        Group each error type under the appropriate category to
                        calculate subscores:
                      </p>
                      <ul>
                        <li>
                          <strong>Grammar &amp; Syntax:</strong> Grammar Minor, Grammar
                          Major, Wrong Sentence Formation (and optionally Omission)
                        </li>
                        <li>
                          <strong>Vocabulary &amp; Expression:</strong> Unidiomatic
                          Usage, Distortion
                        </li>
                        <li>
                          <strong>Fluency &amp; Delivery:</strong> Hesitation, Long
                          Pause, Pronunciation, Tone &amp; Intonation
                        </li>
                        <li>
                          <strong>Content &amp; Relevance:</strong> Missing or off-topic
                          content
                        </li>
                        <li>
                          <strong>Organization &amp; Clarity:</strong> Structural errors
                          impacting flow
                        </li>
                      </ul>
                      <p>
                        For each category, subtract the total deductions from its
                        maximum points.
                      </p>

                      <h4 className="mt-5 mb-3">5. Example Calculation</h4>
                      <p>Imagine a Standard (300-word) dialogue from Student A with:</p>
                      <ul>
                        <li>Grammar Minor: 2</li>
                        <li>Unidiomatic Usage: 1</li>
                        <li>Hesitation (Mild): 2</li>
                        <li>No other errors</li>
                      </ul>
                      <p>
                        <strong>Step 1: Determine deductions per error type.</strong>
                      </p>
                      <ul>
                        <li>Grammar Minor (Standard): 0.35 per error</li>
                        <li>Unidiomatic Usage (Standard): 0.35 per error</li>
                        <li>Hesitation (Mild) (Standard): 0.175 per error</li>
                      </ul>
                      <p>
                        <strong>Step 2: Total deductions per category:</strong>
                      </p>
                      <ul>
                        <li>Grammar &amp; Syntax: 2 × 0.35 = 0.70</li>
                        <li>Vocabulary &amp; Expression: 1 × 0.35 = 0.35</li>
                        <li>Fluency &amp; Delivery: 2 × 0.175 = 0.35</li>
                        <li>Content &amp; Relevance: 0</li>
                        <li>Organization &amp; Clarity: 0</li>
                      </ul>
                      <p>
                        <strong>Step 3: Compute subscores: </strong>
                      </p>
                      <ul>
                        <li>Grammar &amp; Syntax: 25 - 0.70 = 24.30</li>
                        <li>Vocabulary &amp; Expression: 20 - 0.35 = 19.65</li>
                        <li>Fluency &amp; Delivery: 20 - 0.35 = 19.65</li>
                        <li>Content &amp; Relevance: 20 - 0 = 20</li>
                        <li>Organization &amp; Clarity: 15 - 0 = 15</li>
                      </ul>
                      <p>
                        <strong>
                          Step 4: Final Score = 24.30 + 19.65 + 19.65 + 20 + 15 = 98.60
                          (~98.6%)
                        </strong>
                      </p>

                      <h4 className="mt-5 mb-3">6. Benefits</h4>
                      <ul>
                        <li>
                          <strong>Transparency:</strong> Clearly shows which errors
                          affect the score.
                        </li>
                        <li>
                          <strong>Targeted Feedback:</strong> Identifies specific areas
                          for improvement.
                        </li>
                        <li>
                          <strong>Fairness:</strong> Adjusts deductions based on
                          dialogue complexity.
                        </li>
                        <li>
                          <strong>Motivation:</strong> Helps track progress over time.
                        </li>
                      </ul>

                      <h4 className="mt-5 mb-3">7. Implementation Tips</h4>
                      <ul>
                        <li>
                          <strong>Digital Tools:</strong> Use spreadsheets or web forms
                          for data entry.
                        </li>
                        <li>
                          <strong>Automation:</strong> Pre-define deduction values
                          (e.g., via JSON) to automate calculations.
                        </li>
                        <li>
                          <strong>Visualization:</strong> Utilize charts to display
                          category scores and progress.
                        </li>
                        <li>
                          <strong>Flexibility:</strong> Adapt the error table to meet
                          your grading needs.
                        </li>
                      </ul>

                      <h4 className="mt-5 mb-3">Conclusion</h4>
                      <p>
                        This two-part scoring approach—combining a 100-point
                        Category-Based System with Detailed Error Deductions—ensures
                        that both instructors and students receive clear, actionable
                        feedback for continuous language improvement.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Marking;
