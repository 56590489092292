import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Sections = (props) => {
  const { browser, device, selectedProduct, handleStartExersice } = props;

  const sectionTotalCount = selectedProduct?.sections?.length;

  const ConfirmationCreditCheck = (titletext, message) =>
    Swal.fire({
      title: titletext,
      titleText: titletext,
      icon: "info",
      text: message,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28C76F",
      cancelButtonColor: "#FF0000",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleStartExersice(selectedProduct?._id);
      }
    });

  const showDialoguePopup = () => {
    Swal.fire({
      html: "<div class='mt-4 text-start small'><ol><li><b>Listen First:</b> Before you start recording, make sure to listen carefully to the provided audio clip.</li><li><b>Start Recording:</b> Once you're ready, click the record button to begin recording your response.</li><li><b>Time Limit:</b> You can record for up to 2 minutes.</li><li><b>Control Options:</b> You can play, pause, or stop your recording. If you're not happy with it, you can discard it and start over.</li><li><b>Save &amp; Continue:</b> Once you're satisfied with your recording, click the save button to move on to the next dialogue phrase.</li></ol><br /><p>It’s simple: listen carefully, record, review, and save!</p></div>",
      showCloseButton: false,
      width: '60vw',
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonColor: "#1434A4",
      confirmButtonText: "Agree!",
    });
  };

  const popupConfirmationText = (selectedProduct) => {
    let localReturn = "";
    if (selectedProduct?.category?.name === "Complex") {
      localReturn = `Ready to tackle ${selectedProduct?.category?.name} challenges? This session requires ${selectedProduct?.category?.credit} PPC. Are you sure you want to start?`;
    } else if (selectedProduct?.category?.name === "Standard") {
      localReturn = `Practice under ${selectedProduct?.category?.name} conditions. This session requires ${selectedProduct?.category?.credit} PPC. Are you sure you want to start?`;
    } else if (selectedProduct?.category?.name === "Simple") {
      localReturn = `Ease into practice with a ${selectedProduct?.category?.name} session. This session requires ${selectedProduct?.category?.credit} PPC. Are you sure you want to start?`;
    } else if (selectedProduct?.category?.name === "Free") {
      localReturn = `Sharpen your skills with a free practice session!`;
    } else {
      localReturn = `Begin a ${selectedProduct?.category?.name} practice session for ${selectedProduct?.category?.credit} PPC? `;
    }

    return localReturn;
  };

  console.log("browser, device", browser, device);

  return (
    <>
      <Helmet>
        <title>Total {`${sectionTotalCount}`} Dialogue | PhrasePulse</title>
      </Helmet>
      <div className="card-header">
        <h4 className="card-title">{selectedProduct?.name}</h4>
        {selectedProduct?.long_desc && (
          <p className="mt-2">{selectedProduct?.long_desc}</p>
        )}
      </div>
      <div className="card-body">
        <h6 className="card-title mb-3">
          Total {sectionTotalCount} Dialogue Phrases
        </h6>
        {selectedProduct?.sections?.length > 0 && (
          <div className="row">
            <Grid container spacing={2}>
              {selectedProduct?.sections?.map((section) => (
                <Grid key={section.sectionId} item xs={12} md={3} sm={3}>
                  <Card className="dialogue-card" onClick={showDialoguePopup}>
                    <Typography
                      variant="h5"
                      component="div"
                      className="w-100 btn btn-outline-primary btn-lg list"
                    >
                      Phrase {section.sectionId}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <div className="text-start mt-4">
              <button
                className="btn btn-primary btnstl btn-start-practice"
                type="button"
                onClick={() =>
                  ConfirmationCreditCheck(
                    `Start "${selectedProduct?.category?.name || ""
                    }" Practice Session`,
                    popupConfirmationText(selectedProduct)
                  )
                }
              >
                Start Practice
              </button>
            </div>
            <div className="mt-4">
              <b>Note:</b>
              <ul className="ps-0">
                <li>
                  Each practice session will cost the corresponding PPC amount
                  based on the difficulty level chosen.
                </li>
                <li>
                  You will be charged the PPC amount upon starting the practice
                  session.
                </li>
                <li>Good luck and enjoy practicing!</li>
              </ul>
            </div>
            <div className="mt-4">
              <b>PhrasePulse Scoring Guide:</b>
              <p className="m-0">Fair, clear, and actionable feedback for language improvement.</p>
              <p className="mt-2 mb-0">We evaluate dialogues using:</p>
              <ul className="ps-0">
                <li>
                  <b>Category-Based Scoring -</b> Assign up to 100 points across five key skill areas.
                </li>
                <li>
                  <b>Error Deductions -</b> Adjust based on dialogue complexity (Simple, Standard, or Complex).
                </li>
              </ul>
              <p className="mt-2">This guide explains our scoring system with reference tables and an example calculation. <Link to="/user/marking" className="fw-bold">Read more...</Link></p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    browser: state?.reducers?.browser,
    device: state?.reducers?.device,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(Sections);
