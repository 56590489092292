import {
  LOADER_STATE,
  PAGE_WRAPPER_TOGGLE,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  SET_BROWSER_DATA,
  SET_DEVICE_DATA,
  SET_CATEGORY_DATA,
  CLEAR_CATEGORY_DATA,
  SET_PRODUCT_DATA,
  CLEAR_PRODUCT_DATA,
} from "./reducers";
import { GetUserComman } from "./comman";
import { ErrorToast } from "../../Utils/SweetAlert";

export const setLoaderState = (isLoading) => ({
  type: LOADER_STATE,
  payload: isLoading,
});

export const setPageWrapperTaggleState = (wrapperToggle) => ({
  type: PAGE_WRAPPER_TOGGLE,
  payload: wrapperToggle,
});

export const setUserData = (payload = null) => {
  return {
    type: SET_USER_DATA,
    payload: payload,
  };
};

export const clearUserData = () => {
  return { type: CLEAR_USER_DATA };
};

export const setBrowserData = (payload = null) => {
  return {
    type: SET_BROWSER_DATA,
    payload: payload,
  };
};

export const setDeviceData = (payload = null) => {
  return {
    type: SET_DEVICE_DATA,
    payload: payload,
  };
};

export const setCategoryData = (payload = null) => {
  return {
    type: SET_CATEGORY_DATA,
    payload: payload,
  };
};

export const clearCategoryData = () => {
  return { type: CLEAR_CATEGORY_DATA };
};

export const setProductData = (payload = null) => {
  return {
    type: SET_PRODUCT_DATA,
    payload: payload,
  };
};

export const clearProductData = () => {
  return { type: CLEAR_PRODUCT_DATA };
};

export const _getUserDetails = () => (dispatch) => {
  dispatch(setLoaderState(true));
  return GetUserComman()
    .then((res) => {
      dispatch(setLoaderState(false));
      dispatch(setUserData(res?.data?.data));
      return Promise.resolve(res?.data?.data);
    })
    .catch((e) => {
      ErrorToast("Something went wrong! Try again");
      dispatch(setLoaderState(false));
      dispatch(clearUserData());
      return Promise.reject(e);
    });
};
