import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

const TermCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Term and Condition | PhrasePulse</title>
      </Helmet>
      <div className="inner-page">
        <Container>
          <Row>
            <Col>
              <div className="common-page mt-md-5 mt-lg-5">
                <h1>Term & Conditions</h1>
              </div>
              <p>
                Welcome to PhrasePulse.com! We're delighted to have you join our
                community. Before you dive into our website and use our
                services, please take a moment to read through these Terms of
                Service and Support Agreement carefully. By accessing,
                registering for, or using our services, you confirm that you
                have read, understood, and agreed to be bound by these terms and
                conditions. If you are accepting these terms on behalf of
                another person, company, or legal entity, you represent and
                warrant that you have full authority to bind that person,
                company, or legal entity to these terms.
              </p>
              <br />
              <p>
                <b>1. Acceptance of Terms and Conditions</b>
              </p>
              <p>
                By downloading, installing, or using the mobile
                application/website from PhrasePulse.com, you acknowledge that
                you have purchased the software/website from an approved source
                and agree to be bound by the terms of this agreement. If you do
                not agree with any part of these terms, do not download,
                install, copy, access, or use the mobile application/website.
                Promptly delete or return the mobile application/website if you
                do not agree with these terms.
              </p>
              <p>
                <b>2. Definitions</b>
              </p>
              <p>
                Coverage Period: The hours during which our support services are
                available, from 8:00 am to 9:00 pm IST, Monday through Saturday
              </p>
              <p>
                Maintenance Patch: A release that includes the most recent
                resolutions for our supported products.
              </p>
              <p>
                Resolution: A modification, addition, or workaround that
                corrects a material defect in our supported products.
              </p>
              <p>
                Supported Incidents: Material defects or failures of our
                supported products to conform to specifications, resulting in
                restricted use or inability to use the product.
              </p>
              <p>
                Supported Products: The current version of our mobile
                application/website and any prior version for which we provide
                support.
              </p>
              <p>
                <b>3. Service Description and Scope</b>
              </p>
              <p>
                Our support services include technical support and resolution of
                supported incidents related to the configuration and
                administration of our mobile application/website. We aim to
                address your requests within 3 working days.
              </p>
              <p>
                <b>4. Exclusion from Services</b>
              </p>
              <p>
                We are not obligated to provide support services in situations
                such as unsupported incidents, improper installation or
                operation, damages caused by negligence, or incidents caused by
                third-party hardware or software not provided by us.
              </p>
              <p>
                <b>5. Response Criteria-</b>
              </p>
              <p>
                We will respond to service requests within the Coverage Period,
                and our initial response may lead to resolution or further
                action as needed.
              </p>
              <p>
                <b>6. Maintenance Patches and Upgrades/Updates</b>
              </p>
              <p>
                We will provide maintenance patches, upgrades, and updates for
                our supported products as and when they become available. We
                cannot guarantee error-free releases or uninterrupted service
                but will notify you of scheduled maintenance in advance.
              </p>
              <p>
                <b>7. Customer Responsibilities</b>
              </p>
              <div>As a customer, you agree to:</div>
              <div style={{ marginLeft: "20px" }}>
                <p>Pay applicable service fees and communication charges.</p>
                <p>
                  Use access codes provided by us only for authorized personnel
                </p>
                <p>
                  Provide relevant diagnostic information for issue resolution.
                </p>
                <p>
                  Grant remote access if necessary for issue diagnosis and
                  resolution
                </p>
                <p>
                  Provide access to payment transaction information for
                  record-keeping purposes.
                </p>
                <p>
                  Use services only for information processing within your
                  enterprise.
                </p>
                <p>Ensure compliance with licensing terms and regulations.</p>
                <p>Make reasonable efforts to correct identified issues.</p>
              </div>
              <p>
                <b>8. Cooperation</b>
              </p>
              <p>
                Collaborate with us in good faith to resolve issues and deploy
                necessary corrections.
              </p>
              <p>
                <b>9. Compliance</b>
              </p>
              <p>
                Abide by the terms of the license and ensure compliance with all
                relevant regulations and agreements.
              </p>
              <p>
                <b>10. Data Protection</b>
              </p>
              <p>
                Maintain adequate protection for systems and data when providing
                remote access to us.
              </p>
              <p>
                <b>11. Record Keeping</b>
              </p>
              <p>
                Provide access to or information regarding payment transactions
                for record and account clearance purposes
              </p>
              <p>
                <b>12. Usage Restrictions</b>
              </p>
              <p>
                Ensure that services are used solely for the support of
                information processing requirements within your enterprise.
              </p>
              <p>
                <b>13. License Compliance</b>
              </p>
              <p>
                Ensure that services are used only in relation to duly licensed
                supported products.
              </p>
              <p>
                <b>14. Issue Resolution</b>
              </p>
              <p>
                Make reasonable efforts to correct any identified issues and
                deploy necessary corrections following consultation with us.
              </p>
              <p>
                <b>15. Communication</b>
              </p>
              <p>
                Promptly inform us of any changes in hardware location that may
                affect service delivery or support.
              </p>

              <br />
              <p>
                These terms are essential for maintaining effective
                collaboration and ensuring the timely resolution of any issues
                or concerns related to the supported products and services
                provided by PhrasePulse.com.
              </p>
              <p>
                If you have any questions or concerns about these terms or our
                services, please contact us using the information provided
                below.
              </p>

              <br />
              <h2>Contact Information:</h2>
              <div>phrasepulse[at]gmail[dot]com</div>
              <div>
                Address: Tulsi Park - 2, Raiya Telephone Exchange, 150 Feet Ring
                Road, Rajkot Gujarat, India - 360 005
              </div>
              <div>Effective Date: 1st January, 2024</div>
              <div>Last Updated: 1st February, 2025</div>
              <div>
                Thank you for choosing PhrasePulse.com. We look forward to
                serving you!
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TermCondition;
