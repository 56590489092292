import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../../Utils";
import {
  GetClientUserPaginate,
  ActiveInactiveClientUser,
} from "../../../../Services/Api/user";
import { AdminFooter } from "../../../Layout";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  ErrorToast,
  Confirmation,
  SuccessToast,
} from "../../../../Utils/SweetAlert";

const ClientUser = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userListPagination, setuserListPagination] = useState([]);
  const [searchInputWord, setSearchInputWord] = useState(null);
  const [searchWord, setSearchWord] = useState(null);

  const GetUserList = async (limit = "", page = "", searchText = null) => {
    setIsLoading(true);
    await GetClientUserPaginate({
      limit: limit,
      page: page,
      searchText: searchText || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setuserListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setuserListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setuserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeActive = (user, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await ActiveInactiveClientUser(user?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetUserList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleAlphbetSearch = () => {
    if (searchInputWord?.length >= 1) {
      setSearchWord(searchInputWord);
    }
  };

  useEffect(() => {
    GetUserList(limit, page, searchWord);
  }, [limit, page, searchWord]);

  return (
    <>
      <Helmet>
        <title>Students | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Students</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="search-filter mt-2">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchInputWord(e?.target?.value)}
                      value={searchInputWord || ""}
                    />
                    <button
                      className="btn btnstl btn-primary"
                      onClick={(e) => handleAlphbetSearch()}
                    >
                      <SearchIcon />
                    </button>
                    <button
                      className="btn btnstl btn-dark"
                      onClick={(e) => window.location.reload()}
                    >
                      <RestartAltIcon />
                    </button>
                  </div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Created</th>
                          <th>Credit</th>
                          <th>Subscription</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {userListPagination.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <h2 className="table-avatar">
                                    {user?.name || ""}{" "}
                                    <span>{user?.mobile || ""}</span>
                                  </h2>
                                </td>
                                <td>
                                  {moment(user?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || ""}
                                </td>
                                <td>{user?.credit || 0}</td>
                                <td>
                                  {user?.wordStackSubscription === true
                                    ? moment(
                                        user?.subscriptionExpiryDate
                                      ).format("DD-MM-YYYY hh:mm A") || ""
                                    : "No"}
                                </td>
                                <td>{user?.email || "-"}</td>
                                <td>{`${user?.phone_code ?? "-"} ${
                                  user?.phone ?? "-"
                                }`}</td>
                                <td>
                                  {user?.status === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeActive(
                                          user,
                                          "Want to active user?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {user?.status === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeActive(
                                          user,
                                          "Want to deactivate user?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {userListPagination.length === 0 && (
                              <tr>
                                <td colSpan={7}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={7}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientUser;
