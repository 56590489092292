import React from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { AddCredit, AddPaymentGetwayRes } from "../../../Services/Api/payment";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";

const CheckoutForm = (props) => {
  const { amount, initialOptions } = props;
  const [{ isPending }] = usePayPalScriptReducer();

  const addCredit = async (data) => {
    await AddCredit(data)
      .then((res) => {
        if (res?.data?.status) {
          SuccessToast("Payment successfully!!");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          ErrorToast(
            res?.data?.message ||
              "Payment Status Pending. If your money was debeted in your account, Go to support menu and make request."
          );
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const addPaymentGetwayRes = async (data) => {
    await AddPaymentGetwayRes(data);
  };

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order
      .capture()
      .then((details) => {
        // console.log("return details = ", details);
        if (details?.status === "COMPLETED") {
          let payAmount = parseInt(
            details?.purchase_units[0]?.amount?.value,
            10
          );
          if (payAmount) {
            let paymentIntent = {
              id: details?.id,
              capture_id: details?.purchase_units[0]?.payments?.captures[0]?.id,
              order_id: initialOptions?.["data-order-id"],
              amount: payAmount,
            };
            SuccessToast("We will update your status!");
            addPaymentGetwayRes(details);
            addCredit(paymentIntent);
          } else {
            ErrorToast(
              "If your money was debeted in your account, Go to support menu and make request. We will contact you ASAP."
            );
          }
        }
      })
      .catch((err) => {
        // console.log("paypal err = ", err);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  return (
    <div className="checkout">
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          <PayPalButtons
            style={{ layout: "vertical" }}
            fundingSource="card"
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
          />
        </>
      )}
    </div>
  );
};

export default CheckoutForm;
