import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Accordion } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AllAlphbet } from "../../../Utils/CommanFunctions";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import PaginationSimple from "../../../Utils/PaginationSimple";
import {
  GetWordStackPaginate,
  GetWordStackFavourite,
  GetAllWordStackCategory,
} from "../../../Services/Api/wordStack";
import { UserFooter } from "../Layout";
import { Loader } from "../../../Utils/Loader";
import BrowserVoices from "./BrowserVoices";

const WordStock = (props) => {
  const navigate = useNavigate();
  const { userDetails } = props;
  const [page, setPage] = useState(0);
  const [useEffestStatus, setUseEffestStatus] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [wordStack, setWordStack] = useState([]);
  const [alphabet, setAlphabet] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [searchInputWord, setSearchInputWord] = useState("");
  const [searchInputCategory, setSearchInputCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [randomCategories, setRandomCategories] = useState([]);
  const [selectVoicePopup, setSelectVoicePopup] = useState(false);
  const [selectVoice, setSelectVoice] = useState(false);
  const language_id = userDetails?.user?.language_id;
  const limit = 10;
  console.log(selectVoice);

  const getWordStackPaginate = useCallback(
    async (
      limit = "",
      page = "",
      language_id = null,
      searchText = null,
      category_id = null
    ) => {
      setIsLoading(true);
      await GetWordStackPaginate({
        limit: limit,
        page: page,
        language_id: language_id || null,
        searchText: searchText || null,
        categoryId: category_id || null,
      })
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.data?.data?.length) {
            setWordStack(res?.data?.data?.data);
            setTotalRecords(res?.data?.data?.paginate?.total);
          } else {
            setWordStack([]);
            setTotalRecords(0);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.data?.upgrade_subscription) {
            Swal.fire({
              html: "<h4>Access Word Pulse</h4>Unlock all vocabulary exercises with a membership. Only 10 PPC for 30 days. No need for manual cancellation as it expires automatically.",
              showCloseButton: false,
              icon: "warning",
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonColor: "#28C76F",
              cancelButtonColor: "#FF0000",
              confirmButtonText: "Subscribe",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/user/plans");
              }
            });
          }
          setWordStack([]);
          setTotalRecords(0);
          setIsLoading(false);
        });
    },
    [navigate]
  );

  const getAllWordStackCategory = useCallback(async () => {
    await GetAllWordStackCategory()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoriesData = res?.data?.data?.map((category) => {
            return {
              label: category?.name,
              value: category?._id,
            };
          });
          const selectedCategories = getRandomCategories(categoriesData, 30);
          setRandomCategories(selectedCategories);
          setCategories(categoriesData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  }, []);

  const getRandomCategories = (categories, count) => {
    const shuffled = categories?.sort(() => 0.5 - Math.random());
    return shuffled?.slice(0, count);
  };

  const getWordStackFavourite = async (id) => {
    setIsLoading(true);
    await GetWordStackFavourite(id)
      .then((res) => {
        setIsLoading(false);
        SuccessToast(res?.data?.message || "Updated");
        setUseEffestStatus(true);
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handleAlphbetSearch = (text, alphabet = false) => {
    if (alphabet) {
      setAlphabet(text);
      setSearchInputWord("");
    } else {
      setAlphabet("");
    }
    setPage(0);
    setSearchWord(text);
  };

  const handleCategorySearch = (category_id) => {
    setCategoryId(categoryId !== category_id ? category_id : null);
    setPage(0);
  };

  const handleCategoryInputChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    if (searchValue?.length >= 3) {
      const filtered = categories?.filter((category) =>
        category.label.toLowerCase().includes(searchValue)
      );
      setRandomCategories(filtered);
    } else if (searchValue?.length === 0) {
      const selectedCategories = getRandomCategories(categories, 10);
      setRandomCategories(selectedCategories);
    }
    setSearchInputCategory(searchValue);
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleSpeak = (text) => {
    if ("speechSynthesis" in window) {
      const value = new SpeechSynthesisUtterance(text);
      value.lang = "en-US";
      window.speechSynthesis.speak(value);
    } else {
      ErrorToast(
        "Text to speech is not supported in this browser. Please upgrade to a modern browser."
      );
    }
  };

  useEffect(() => {
    getWordStackPaginate(limit, page, language_id, searchWord, categoryId);
  }, [
    limit,
    page,
    language_id,
    searchWord,
    categoryId,
    useEffestStatus,
    getWordStackPaginate,
  ]);

  useEffect(() => {
    getAllWordStackCategory();
  }, [getAllWordStackCategory]);

  return (
    <>
      <Helmet>
        <title>Word Pulse | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  Word Pulse
                  <button
                    className="btn btn-primary btnstl float-end"
                    onClick={() => setSelectVoicePopup(true)}
                  >
                    Browser Voices
                  </button>
                  {selectVoicePopup && (
                    <BrowserVoices
                      selectVoicePopup={selectVoicePopup}
                      setSelectVoicePopup={setSelectVoicePopup}
                      setSelectVoice={setSelectVoice}
                    />
                  )}
                </h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Word Pulse</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          <div className="card mb-0 d-none">
            <div className="card-body pb-0">
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="word-stock-accordion-header">
                    Search by Clusters
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex my-2">
                      <div className="col-3">
                        <p className="mt-2 mb-1 d-none d-sm-block">
                          Search to Categories:{" "}
                        </p>
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input three characters to look up the category"
                          value={searchInputCategory}
                          onChange={handleCategoryInputChange}
                        />
                      </div>
                    </div>
                    <hr />
                    {randomCategories && (
                      <div className="word-category-wrapper">
                        <div className="word-category-list">
                          {randomCategories?.map((category, index) => (
                            <div
                              className={
                                categoryId === category?.value
                                  ? "word-category-item active"
                                  : "word-category-item"
                              }
                              key={index}
                              onClick={() =>
                                handleCategorySearch(category?.value, true)
                              }
                            >
                              {category?.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {AllAlphbet && (
            <div className="card mb-0">
              <div className="card-body pb-0 pt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="word-stock-accordion-header">
                      Search by Alphabet
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="letter-list">
                        {AllAlphbet?.map((letter, index) => (
                          <div
                            className={
                              alphabet === letter
                                ? "letter-item active"
                                : "letter-item"
                            }
                            key={index}
                            onClick={() => handleAlphbetSearch(letter, true)}
                          >
                            {letter}
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          )}

          <div className="card mb-0">
            <div className="card-body">
              <div className="search-filter">
                <p className="mt-2 mb-1 d-none d-sm-block">Search to Word: </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchInputWord(e.target.value)}
                />
                <button
                  className="btn btnstl btn-primary"
                  onClick={(e) => handleAlphbetSearch(searchInputWord)}
                >
                  Search
                </button>
                <button
                  className="btn btnstl btn-dark"
                  onClick={(e) => window.location.reload()}
                >
                  Reset
                </button>
              </div>

              {!isLoading && (
                <div className="table-responsive custom-pagination">
                  <table className="table table-center table<img<image-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>Original</th>
                        <th>Listen</th>
                        <th>Translate</th>
                        <th>Favorite</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wordStack?.map((word, index) => (
                        <tr key={index}>
                          <td>{word?.first_language || ""}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              className="play-btn p-0"
                              onClick={() => handleSpeak(word?.first_language)}
                            >
                              <PlayArrowOutlinedIcon />
                            </Button>
                          </td>
                          <td>{word?.second_language || ""}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              onClick={() => getWordStackFavourite(word?._id)}
                            >
                              {word?.favouriteWordStack ? (
                                <FavoriteIcon />
                              ) : (
                                <FavoriteBorderIcon />
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))}
                      {wordStack?.length === 0 && (
                        <tr>
                          <td colSpan={4}>No Word Found.</td>
                        </tr>
                      )}
                      {isLoading && (
                        <tr>
                          <Loader loading={isLoading} />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <PaginationSimple
                  totalItem={totalRecords}
                  itemsPerPage={limit}
                  page={page}
                  handleChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
    browser: state?.reducers?.browser,
    device: state?.reducers?.device,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(WordStock);
