import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const SuccessToast = (message, time = 5000) =>
  Toast.fire({
    icon: "success",
    title: message,
    timer: time,
  });

export const ErrorToast = (message, time = 5000) =>
  Toast.fire({
    icon: "error",
    title: message,
    timer: time,
  });

export const WarningToast = (message) =>
  Toast.fire({
    icon: "warning",
    title: message,
  });

export const InfoToast = (message) =>
  Toast.fire({
    icon: "info",
    title: message,
  });

export const Confirmation = (
  message,
  localTitle = "Are you sure?",
  cancleButton = true
) =>
  Swal.fire({
    title: localTitle,
    icon: "warning",
    text: message,
    showCancelButton: cancleButton,
    allowOutsideClick: false,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    confirmButtonColor: "#22CC62",
    cancelButtonColor: "#FF0000",
    reverseButtons: true,
  });

export const TextArea = async ({ denyButtonText = "Cancel" }) =>
  await Swal.fire({
    input: "textarea",
    name: "reason",
    inputLabel: "Message",
    inputPlaceholder: "Type your reason here...",
    inputAttributes: {
      "aria-label": "Type your message here",
    },
    confirmButtonText: "Reject",
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: "#dc3741",
    denyButtonColor: "#3085d6",
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm: (msg) => {
      if (!msg) {
        Swal.showValidationMessage(`Please enter reason`);
      }
    },
  });

export const AddFundPopup = async ({ denyButtonText = "Cancel", label }) =>
  Swal.fire({
    title: `<h4 className="my-2">${label}</h4>`,
    html: `<input type="number" name="fund_amount" id="fund_amount" min="1" className="swal2-input" placeholder="Please enter amount">
      <textarea name="notes" id="notes" className="swal2-input height-5em" placeholder="Please enter notes"></textarea>`,
    confirmButtonText: label,
    denyButtonText: denyButtonText,
    confirmButtonColor: "#15d57c",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const fund_amount = Swal.getPopup().querySelector("#fund_amount").value;
      const notes = Swal.getPopup().querySelector("#notes").value;
      if (!fund_amount && !notes) {
        Swal.showValidationMessage(`Please enter amount and notes`);
      } else if (!fund_amount) {
        Swal.showValidationMessage(`Please enter amount`);
      } else if (!notes) {
        Swal.showValidationMessage(`Please enter notes`);
      }

      return { amount: fund_amount, notes: notes };
    },
  }).then((result) => {
    return result;
  });
