import Api from "../../Utils/Axios";

export const AddUserService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/user/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUsersListService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/user/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetProfileService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/user/get-profile`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserPaginate = async ({ limit, page, searchText }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let search_text = searchText !== null ? `&search=${searchText}` : ``;

      resolve(
        await Api.get(
          `/user/paginate?per_page=${limit}&page=${page}${search_text}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteUserById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/user/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/user/change-status/${userId}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/users/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditUserService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/admin/users/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetClientUserPaginate = async ({ limit, page, searchText }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let search_text = searchText !== null ? `&search=${searchText}` : ``;

      resolve(
        await Api.get(
          `/client/user/paginate?per_page=${limit}&page=${page}${search_text}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveClientUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/client/user/change-status/${userId}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};
