import React, { useEffect } from "react";
import { ErrorToast, SuccessToast } from "./SweetAlert";
import MicOffIcon from "@mui/icons-material/MicOff";

const MicrophoneAccess = ({ hasMicAccess, setHasMicAccess }) => {
  const requestMicAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setHasMicAccess(true);
      // Use the microphone stream here (e.g., for recording)
      // ...
      stream.getTracks().forEach((track) => track.stop()); // Stop the stream when done
    } catch (error) {
      ErrorToast("Error accessing microphone: " + error);
      // Handle access denial or other errors
    }
  };

  useEffect(() => {
    // Check for initial microphone access (optional)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        setHasMicAccess(true);
        SuccessToast("Microphone Permission Granted!!");
      })
      .catch(() => setHasMicAccess(false));
  }, [setHasMicAccess]);

  return (
    !hasMicAccess && (
      <div className="col-lg-6 col-mg-6 col-sm-6">
        <div className="d-flex justify-content-center align-items-center text-center">
          <div
            className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
            onClick={() => requestMicAccess()}
          >
            <button className="btn btn-danger">
              <MicOffIcon />
            </button>
            <div className="text-danger">Allow access to the microphone, please.</div>
          </div>
        </div>
      </div>
    )
  );
};

export default MicrophoneAccess;
