import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Options = (props) => {
  const { sections, sectionCurrentCount } = props;

  return (
    <>
      <h3>Options</h3>
      {sections &&
        sections[sectionCurrentCount - 1].options.map((option) => (
          <CardContent key={option._id}>
            <Typography gutterBottom variant="h5" component="div">
              {option.name}
            </Typography>
          </CardContent>
        ))}
      <h3>User Voice in Text</h3>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {sections[sectionCurrentCount - 1]?.userText}
        </Typography>
      </CardContent>
    </>
  );
};

export default Options;
